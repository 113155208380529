import {createRouter, createWebHistory} from 'vue-router';
import HomeGlobal from '@/components/HomeGlobal.vue';
import HomeKr from '@/components/HomeKr.vue';
import HomeJa from "@/components/HomeJa.vue";
import NotFound from '@/components/NotFound.vue';
import {preLoadWallpapers} from "@/assets/util/image";
import HomeEn from "@/components/HomeEn.vue";

const routes = [
    {
        path: '/',
        component: HomeGlobal,
        meta: {title: 'Student Maker'},
        // 브라우저 언어에 따라 한국어, 일본어 페이지로 리다이렉트
        beforeEnter: (to, from, next) => {
            // noinspection JSDeprecatedSymbols
            const userLang = navigator.language || navigator.userLanguage;
            const languageCode = userLang.split('-')[0];
            if (languageCode === 'ko') {
                next('/ko');
            } else if (languageCode === 'ja') {
                next('/ja');
            } else if (languageCode === 'en') {
                next('/en');
            } else {
                next();
            }
        }
    },
    {
        path: '/ko',
        component: HomeKr,
        meta: {title: '수험생 키우기'},
        beforeEnter: () => {
            preLoadWallpapers();
        }
    },
    {
        path: '/ja',
        component: HomeJa,
        meta: {title: '目指せ難関大!'},
        beforeEnter: () => {
            preLoadWallpapers();
        }
    },
    {
        path: '/en',
        component: HomeEn,
        meta: {title: 'Korean Student Simulator'},
        beforeEnter: () => {
            preLoadWallpapers();
        }
    },
    {
        path: '/:pathMatch(.*)*', // 잘못된 경로는 404 페이지로 연결
        component: NotFound,
        beforeEnter: (to, from, next) => {
            next('/');
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to) => {
    document.title = to.meta.title || 'Student Maker';
});

export default router;
